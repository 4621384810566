/* eslint-disable @next/next/no-img-element */
'use client';

import { useRouter } from 'next/navigation';
import React from 'react';

import style from './CustomNotFound.module.css';

import { useTitle } from '../../../src/hooks/useTitle';

import { Button } from '@/components/Button/Button';
import SvgLeftArrow from '@/icons/components/LeftArrow';

const CustomNotFound: React.FC<{}> = () => {
  const router = useRouter();
  useTitle('Page not found');

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.leftGrid}>
        <div className={style.leftGridContent}>
          <div className={style.error404}>404 error</div>
          <h1 className={style.header}>Page not found</h1>
          <p className={style.errorMessage}>
            Sorry, the page you are looking for doesn&apos;t exist or
            <br />
            has been moved.
          </p>
          <div className={style.buttonsContainer}>
            <button className={style.goBackBtn} onClick={goBack}>
              <SvgLeftArrow />
              Go back
            </button>
            <Button label={'Take me home'} onClick={() => router.push('/')} />
          </div>
        </div>
      </div>
      <div className={style.rightGrid}>
        <div className={style.rightGridContent}></div>
        <img
          src="./images/404Error.png"
          alt="404Error"
          className={style.errorBanner}
        />
      </div>
    </div>
  );
};

export default CustomNotFound;
